<template>
	<div class="page container">

		<div class="page__info" v-if="getEnrolment">
			<div class="page__info--title">
				{{ getEnrolmentTitle }}
			</div>
		</div>

		<div>
			<list-view
				v-if="getEnrolment"
				:list="getList"
				:actions="getActions"
				@actionClick="onActionClick"
			/>
			<validation-observer
				v-if="getEnrolment"
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="submit"
			>
				<textarea-field
					name="Note"
					v-model="enrolment.note"
					label="Note"
				/>
				<actions
					:actions="getNoteActions"
					@actionClick="onActionClick"
				/>
			</validation-observer>
		</div>
	</div>
</template>

<script>
	import ListView            from '@/components/ui/views/listView/ListView';
	import TextareaField       from '@/components/forms/TextareaField';
	import Actions             from '@/components/ui/Actions';
	import actionClick         from '@/mixins/actionClick';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import routeParams         from '@/mixins/routeParams';
	import api                 from '@/services/api';
	import dayjs               from 'dayjs';
	import relativeTime        from 'dayjs/plugin/relativeTime';

	dayjs.extend(relativeTime);

	export default {
		layout: 'v2default',
		metaInfo () {
			if (!this.getEnrolment) {
				return false;
			}
			return {
				title: this.enrolment.title
			};
		},
		components: {
			ListView,
			TextareaField,
			Actions
		},
		mixins: [actionClick, routeParams, invalidFormMessage],
		data: () => ({
			enrolment: undefined
		}),
		computed: {
			getEnrolment () {
				if (!this.enrolment ||
						!this.enrolment.enrolmentId) {
					return false;
				}
				return this.enrolment;
			},
			getEnrolmentTitle () {
				if (!this.getEnrolment) {
					return false;
				}
				return this.enrolment.title;
			},
			getBreadcrumbRoute () {
				if (!this.getEnrolmentId) {
					return false;
				}
				return `/admin/users/${this.getUserId}/purchases/`;
			},
			getStudentNameString (enrolment) {
				if (!this.getEnrolment) {
					return false;
				}
				return `${this.getEnrolment.firstName} ${this.getEnrolment.lastName}`;
			},
			getJoinDateString () {
				if (!this.enrolment.joinDate) {
					return '--';
				}
				return dayjs().to(this.enrolment.joinDate);
			},
			getActions () {
				if (!this.getEnrolment) {
					return false;
				}
				return {
					secondary : [
						(this.getEnrolment.activated ?
							{
								text: 'Remove Access',
								actionId: 'makeInactive'
							} : {
								text: 'Grant Access',
								actionId: 'makeActive'
							})
					].filter(Boolean)
				};
			},
			getNoteActions () {
				if (!this.getEnrolment) {
					return false;
				}
				return {
					primary : [
						{
							text: 'Save Note',
							actionId: 'saveNote'
						}
					].filter(Boolean)
				};
			},
			getList () {
				if (!this.getEnrolment) {
					return false;
				}
				return [
					{
						text: `Teacher: ${this.getEnrolment.teacherName}`
					},
					{
						text: `Date: ${this.getJoinDateString}`
					},
					{
						text: `Cost: ${this.getEnrolment.cost}`
					},
					{
						text: `Active: ${(this.getEnrolment.activated) ? 'Yes' : 'No'}`
					}
				].filter(Boolean);
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/users',
						text: 'Users'
					},
					{
						path: `/admin/users/${this.getUserId}`,
						text: this.getStudentNameString
					},
					{
						path: `/admin/users/${this.getUserId}/purchases`,
						text: 'Purchases'
					},
					{
						path: `/admin/users/${this.getUserId}/purchases/${this.getEnrolmentId}`,
						text: this.getEnrolmentTitle
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.setEnrolment();
		},
		methods: {
			async setEnrolment () {
				const enrolment = await api.admin.getEnrolmentById({
					enrolmentId: this.getEnrolmentId
				});
				if (!enrolment) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.enrolment = enrolment;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async makeActive () {
				const result = await api.admin.setEnrolmentIsActive({
					enrolmentId: this.getEnrolmentId,
					note: this.getEnrolment.note
				});
				this.enrolment = result;
				this.$store.commit('ui/showNotification', {
					notification: 'Enrolment access granted!'
				});
			},
			async makeInactive () {
				await api.admin.setEnrolmentIsInactive({
					enrolmentId: this.getEnrolmentId
				});
				await this.saveNoteContent();
				this.$store.commit('ui/showNotification', {
					notification: 'Enrolment access removed!'
				});
			},
			async saveNote () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				await this.saveNoteContent();
				this.$store.commit('ui/showNotification', {
					notification: 'Note saved!'
				});
			},
			async saveNoteContent () {
				const enrolment = await api.admin.setNoteContentByEnrolmentId({
					enrolmentId: this.getEnrolmentId,
					note: this.getEnrolment.note
				});
				this.enrolment = enrolment;
				return enrolment;
			}
		}
	};

</script>
